import React, { useEffect, useState } from 'react';
import TaskColumn from './TaskColumn';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import withScrolling from 'react-dnd-scrolling';
import './TaskBoard.css';
import { Spinner } from '@chakra-ui/react';
import { Column } from '../../types/taskboard.types';
import taskBoardData from './taskboard.data';

const TaskBoard = () => {
  const [columns, setColumns] = useState<Column[]>();
  console.log(
    'taskboard render columns:',
    columns?.map((c, i) => c.id + i.toString()).join('|'),
  );
  useEffect(() => {
    setColumns(taskBoardData);
  }, []);
  const ScrollingComponent = withScrolling('div');
  if (!columns) {
    return <Spinner />;
  }

  const handleMoveCard = (
    cardId: string,
    fromColumnId: string,
    toColumnId: string,
  ) => {
    const newColumns = [...columns];
    const fromColumn = newColumns.find((c) => c.id === fromColumnId);
    const card = fromColumn?.cards?.find((c) => c.id === cardId);
    const toColumn = newColumns.find((c) => c.id === toColumnId);
    if (fromColumn && toColumn && card) {
      card.columnId = toColumnId;
      toColumn?.cards?.push(card);
      fromColumn.cards = fromColumn?.cards?.filter((c) => c.id !== cardId);
      setColumns(newColumns);
    }
  };

  const handleMoveColumn = (id: string, toColumnId: string) => {
    const fromIndex = columns.findIndex((c) => c.id === id);
    const toIndex = columns.findIndex((c) => c.id === toColumnId);
    console.log(
      'move column',
      id,
      'fromIndex',
      fromIndex,
      'to',
      toColumnId,
      'toIndex',
      toIndex,
    );
    if (fromIndex >= 0 && toIndex >= 0) {
      const newColumns = [...columns];
      console.log('fromIndex', fromIndex, 'toIndex', toIndex);
      const b = newColumns.splice(fromIndex, 1);
      console.log(b);
      newColumns.splice(toIndex, 0, ...b);
      setColumns(newColumns);
    }
  };

  return (
    <DndProvider backend={HTML5Backend} debugMode={true}>
      <ScrollingComponent className="task-board-container">
        {columns.map((col) => (
          <TaskColumn
            key={col.id}
            id={col.id}
            name={col.name}
            cards={col.cards}
            onMoveCard={handleMoveCard}
            onMoveColumn={handleMoveColumn}
          />
        ))}
      </ScrollingComponent>
    </DndProvider>
  );
};

export default TaskBoard;
