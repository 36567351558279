import { Flex, Spacer } from '@chakra-ui/react';

import AppLogo from './AppLogo';
import UserMenu from './UserMenu';

const Header = () => {
  return (
    <Flex
      className="app-header"
      minWidth="max-content"
      alignItems="center"
      gap="2"
      padding="8px"
      height="56px"
      bgColor="#e0e0e0"
    >
      <AppLogo />
      <Spacer />
      <UserMenu />
    </Flex>
  );
};

export default Header;
