import { Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';

const TopHeader = () => {
  return (
    <Flex className="app" flexDir="column" height="100vh">
      <Header />
      <Outlet />
    </Flex>
  );
};

export default TopHeader;
