import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Column } from '../../types/taskboard.types';
import TaskCard from './TaskCard';

export interface TaskColumnProps extends Column {
  onMoveCard: (id: string, fromColumnId: string, toColumnId: string) => void;
  onMoveColumn: (id: string, toColumnId: string) => void;
}
const TaskColumn: React.FC<TaskColumnProps> = ({
  id,
  name,
  cards,
  onMoveCard,
  onMoveColumn,
}) => {
  const columnRef = useRef<HTMLDivElement>(null);
  const [dep, setDep] = useState<number>(0);
  const [{ isDragging }, columnDragRef] = useDrag(
    () => ({
      item: { id, type: 'Column' },
      type: 'Column',
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [dep],
  );

  const [{ canDrop, isOver }, columnDropRef] = useDrop(
    () => ({
      accept: ['Card', 'Column'],
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      canDrop: (item, monitor) => {
        if (item.type === 'Column') {
          return item.id !== id;
        } else if (item.type === 'Card') {
          return item.columnId !== id;
        }
        return false;
      },
      drop: (item: any) => {
        console.log('drop', item);
        if (item.type === 'Card') {
          onMoveCard(item.id, item.columnId, id);
        } else if (item.type === 'Column') {
          onMoveColumn(item.id, id);
        }
        setDep(dep + 1);
      },
    }),
    [dep],
  );

  columnDragRef(columnDropRef(columnRef));

  return (
    <div
      ref={columnRef}
      className={`task-column 
      ${isDragging ? 'drag' : ''} 
      ${isOver && canDrop ? 'drop' : ''}`}
    >
      <div className="task-column-header">{name}</div>
      <div className="task-column-content">
        <div className="task-column-cards">
          {cards?.map((card) => (
            <TaskCard
              key={card.id}
              id={card.id}
              columnId={card.columnId}
              name={card.name}
            />
          ))}
        </div>
      </div>
      <div className="task-column-footer">{cards?.length ?? 'No'} task(s)</div>
    </div>
  );
};

export default TaskColumn;
