import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import useProfile from '../../hooks/useProfile';
import { RoutePaths } from '../../pages';

const UserMenu = () => {
  const { loading, profile, signIn, signOut } = useProfile();
  console.log('UserMenu render', loading, profile);
  const navigate = useNavigate();

  const handleSignIn = () => {
    signIn();
  };

  const handleSignOut = () => {
    signOut();
  };

  const handleProfile = () => {
    navigate(RoutePaths.Profile);
  };

  if (profile === undefined) {
    return <></>;
  }
  if (profile === null) {
    return (
      <Button variant="solid" onClick={handleSignIn}>
        Sign In
      </Button>
    );
  }

  return (
    <Menu>
      <MenuButton>
        <Avatar size="sm" name={profile.name} src={profile.avatarUrl} />
        <ChevronDownIcon />
      </MenuButton>
      <MenuList>
        <MenuGroup title={profile.name}>
          <MenuItem onClick={handleProfile}>Profile</MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
