import { useContext } from "react";

import { ProfileContext } from "../components/ProfileProvider/ProfileProvider";

const useProfile = () => {
  const { loading, profile, signIn, signOut } = useContext(ProfileContext);

  return { loading, profile, signIn, signOut };
};

export default useProfile;
