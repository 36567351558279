import { ComponentType, FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useProfile from '../../hooks/useProfile';

export interface PrivateRouteProps {
  redirect?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  children,
  redirect,
}: PropsWithChildren<PrivateRouteProps>) => {
  const auth = useAuth();
  const { signIn } = useProfile();

  if (auth === true) {
    return <>{children}</>;
  } else if (auth === false && redirect) {
    signIn();
  }

  return <></>;
};

export const withPrivateRoute =
  (Component: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren) => {
    const auth = useAuth();

    console.log('withPrivateRoute', auth);

    if (auth === true) {
      return <Component {...props} />;
    }

    if (auth === false) {
      return <Navigate to="/" />;
    }

    return <></>;
  };

export default PrivateRoute;
