import { Column } from '../../types/taskboard.types';

const TaskBoardData: Column[] = [
  {
    id: 'a',
    name: 'Column A',
    cards: [
      { id: 'a1', columnId: 'a', name: 'Card A1' },
      { id: 'a2', columnId: 'a', name: 'Card A2' },
      { id: 'a3', columnId: 'a', name: 'Card A3' },
      { id: 'a4', columnId: 'a', name: 'Card A4' },
      { id: 'a5', columnId: 'a', name: 'Card A5' },
    ],
  },
  {
    id: 'b',
    name: 'Column B',
    cards: [
      { id: 'b1', columnId: 'b', name: 'Card B1' },
      { id: 'b2', columnId: 'b', name: 'Card B2' },
      { id: 'b3', columnId: 'b', name: 'Card B3' },
    ],
  },
  {
    id: 'c',
    name: 'Column C',
    cards: [
      { id: 'c1', columnId: 'c', name: 'Card C1' },
      { id: 'c2', columnId: 'c', name: 'Card C2' },
      { id: 'c3', columnId: 'c', name: 'Card C3' },
      { id: 'c4', columnId: 'c', name: 'Card C4' },
      { id: 'c5', columnId: 'c', name: 'Card C5' },
      { id: 'c6', columnId: 'c', name: 'Card C6' },
      { id: 'c7', columnId: 'c', name: 'Card C7' },
      { id: 'c8', columnId: 'c', name: 'Card C8' },
      { id: 'c9', columnId: 'c', name: 'Card C9' },
    ],
  },
  {
    id: 'd',
    name: 'Column D',
    cards: [],
  },
  {
    id: 'e',
    name: 'Column E',
    cards: [
      { id: 'e1', columnId: 'e', name: 'Card E1' },
      { id: 'e2', columnId: 'e', name: 'Card E2' },
      { id: 'e3', columnId: 'e', name: 'Card E3' },
      { id: 'e4', columnId: 'e', name: 'Card E4' },
      { id: 'e5', columnId: 'e', name: 'Card E5' },
      { id: 'e6', columnId: 'e', name: 'Card E6' },
      { id: 'e7', columnId: 'e', name: 'Card E7' },
    ],
  },
  {
    id: 'f',
    name: 'Column F',
    cards: [{ id: 'f1', columnId: 'f', name: 'Card F1' }],
  },
  {
    id: 'g',
    name: 'Column G',
    cards: [
      { id: 'g1', columnId: 'g', name: 'Card G1' },
      { id: 'g2', columnId: 'g', name: 'Card G2' },
      { id: 'g3', columnId: 'g', name: 'Card G3' },
    ],
  },
];

export default TaskBoardData;
