import { Heading } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import { RoutePaths } from '../../pages';

const AppLogo = () => {
  return (
    <Link to={RoutePaths.Home}>
      <Heading size="sm">toD.one Beta</Heading>
    </Link>
  );
};

export default AppLogo;
