import './index.css';

import { Auth } from '@aws-amplify/auth';
import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import App from './App';

Auth.configure({
  identityPoolId: 'us-east-1:5845329e-eacc-42ab-a5bd-4a1e6702ce2a',
  region: 'us-east-1',
  userPoolId: 'us-east-1_4Qhs7MWRY',
  userPoolWebClientId: '3q56bso4livgdmtg6pqfcqvc1p',
  oauth: {
    domain: 'accounts.tod.one',
    scope: ['openid', 'email', 'profile'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: 'code',
  },
});

const httpLink = createHttpLink({
  uri: new URL('/graphql', process.env.REACT_APP_API_URL).toString(),
});

const getToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (err) {
    console.log('error getting token for query', err);
  }
};
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists

  const token = await getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ChakraProvider>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </ChakraProvider>,
);
