import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';

export interface FetchHookProps {
  url: URL;
  options?: RequestInit;
}

const getBearerToken = async () => {
  const session = await Auth.currentSession();

  if (!session.isValid()) {
    throw Error('INVALID USER SESSION');
  }
  return session.getAccessToken().getJwtToken();
};
const useFetch = <T>({ url, options }: FetchHookProps) => {
  const [loaded, setLoaded] = useState<boolean>();
  const [data, setData] = useState<T>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const doFetch = async () => {
      setLoaded(false);
      const jwtToken = await getBearerToken();

      const headers = { Authorization: `Bearer ${jwtToken}` };
      await fetch(url, { ...options, headers, signal })
        .then((response) => {
          if (!response.ok) {
            throw Error(`response not ok`);
          }
          return response.json();
        })
        .then((responseData) => {
          if (!signal.aborted) {
            setData(responseData);
          }
        })
        .catch((err) => {
          if (!signal.aborted) {
            setData(undefined);
            setError(err.message);
          }
        })
        .finally(() => setLoaded(true));
    };

    doFetch();

    return () => {
      abortController.abort();
    };
  }, []);

  return { loaded, error, data };
};

export default useFetch;
