import React, { PropsWithChildren } from 'react';
import PageTitle from './PageTitle';
import './Page.css';

export interface PageProps {
  title?: string;
}
const Page = ({ title, children }: PropsWithChildren<PageProps>) => {
  return (
    <div className="page">
      {title && <PageTitle title={title} />}
      <div className="page-content">{children}</div>
    </div>
  );
};

export default Page;
