import { Auth } from '@aws-amplify/auth';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_PROFILE, SAVE_USER_PROFILE } from '../../queries/UserQuery';

export interface ProfileContextInterface {
  profile: UserProfile | null | undefined;
  loading: boolean;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
}

export interface UserProfile {
  email: string;
  id: string;
  name: string;
  avatarUrl?: string;
}

const providerValue: ProfileContextInterface = {
  profile: undefined,
  loading: true,
  signIn: async () => {},
  signOut: async () => {},
};

export const ProfileContext =
  createContext<ProfileContextInterface>(providerValue);

const getProfileFromToken = (token: any) => {
  const { name, sub, email, picture } = token.payload;
  return {
    name: name,
    id: sub,
    email,
    avatarUrl: picture,
  };
};

const ProfileProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [profile, setProfile] = useState<UserProfile | null | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<UserProfile>();

  const [getUserProfile] = useLazyQuery(GET_USER_PROFILE, {
    onCompleted: (data) => {
      console.log('getUserProfile completed');
      if (data.me?.id) {
        setProfile(data.me);
      } else {
        console.log('no profile - saving user profile', token);
        saveUserProfile({
          variables: {
            name: token?.name,
            email: token?.email,
            avatarUrl: token?.avatarUrl,
          },
        });
      }
    },
    onError: (err) => console.log('query error', err),
  });

  const [saveUserProfile] = useMutation(SAVE_USER_PROFILE, {
    onCompleted: (data) => {
      setProfile(data.saveProfile);
    },
    onError: (err) => console.log('mutation error', err),
  });

  useEffect(() => {
    console.log('ProfileProvider useEffect');
    Auth.currentSession()
      .then((session) => {
        console.log('current session', session);
        if (session.isValid()) {
          const user = getProfileFromToken(session.getIdToken());
          setToken(user);
          console.log('ProfileProvider getting userprofile');
          getUserProfile();
        }
      })
      .catch((err) => setProfile(null))
      .finally(() => setLoading(false));
  }, []);

  const signIn = async () => {
    Auth.federatedSignIn()
      .then((data) => console.log('fedauth data', data))
      .catch((err) => console.log('fedauth error', err));
  };

  const signOut = async () => {
    return Auth.signOut().then(() => {
      setProfile(null);
    });
  };

  if (loading) {
    return <>...</>;
  }

  return (
    <ProfileContext.Provider value={{ loading, profile, signIn, signOut }}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
