import React from 'react';
import { Card } from '../../types/taskboard.types';
import { useDrag } from 'react-dnd';

const TaskCard: React.FC<Card> = ({ id, columnId, name }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    item: { id, columnId, type: 'Card' },
    type: 'Card',
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} className={`task-card ${isDragging ? 'drag' : ''}`}>
      {name}
    </div>
  );
};

export default TaskCard;
