import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';

const useAuth = () => {
  const [auth, setAuth] = useState<boolean>();

  useEffect(() => {
    Auth.currentSession()
      .then((session) => setAuth(session.isValid()))
      .catch((err) => setAuth(false));
  }, []);
  return auth;
};

export default useAuth;
