import { Navigate } from 'react-router-dom';
import { RoutePaths } from '..';
import useAuth from '../../hooks/useAuth';
import useProfile from '../../hooks/useProfile';

const SignIn = () => {
  const auth = useAuth();
  const { signIn } = useProfile();

  if (auth === true) {
    return <Navigate to={RoutePaths.Home} />;
  }

  if (auth === false) {
    signIn();
  }

  return <></>;
};

export default SignIn;
