import React from 'react';
import Page from '../../components/Page/Page';
import { withPrivateRoute } from '../../components/PrivateRoute/PrivateRoute';
import TaskBoard from '../../components/TaskBoard/TaskBoard';

const Dashboard = () => {
  return (
    <Page title="Task Board">
      <TaskBoard />
    </Page>
  );
};

export default withPrivateRoute(Dashboard);
