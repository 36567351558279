import './App.css';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ProfileProvider from './components/ProfileProvider/ProfileProvider';
import { RoutePaths } from './pages';
import Home from './pages/Home/Home';
import Profile from './pages/Profile/Profile';
import Users from './pages/Users/Users';
import FullPage from './layouts/FullPage';
import TopHeader from './layouts/TopHeader';
import SignIn from './pages/SignIn/SignIn';

function App() {
  return (
    <ProfileProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<FullPage />}></Route>
          <Route element={<TopHeader />}>
            <Route index element={<Home />} />
            <Route path={RoutePaths.Profile} element={<Profile />} />
            <Route path={RoutePaths.Users} element={<Users />} />
            <Route path={RoutePaths.SignIn} element={<SignIn />} />
            <Route path="*" element={<Navigate to={RoutePaths.Home} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ProfileProvider>
  );
}

export default App;
