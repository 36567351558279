import { useEffect, useState } from 'react';
import { withPrivateRoute } from '../../components/PrivateRoute/PrivateRoute';

export interface UserDto {
  id?: string;
  name: string;
  email: string;
}

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [users, setUsers] = useState<UserDto[]>();

  useEffect(() => {
    const fetchUsers = () => {
      setLoading(true);
      fetch(new URL('/user', process.env.REACT_APP_API_URL))
        .then((res) => {
          if (!res.ok) {
            throw Error('Unable to load users');
          }
          return res.json();
        })
        .then((data) => {
          setError(undefined);
          setLoading(false);
          setUsers(data);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
          setUsers(undefined);
        });
    };
    fetchUsers();
  }, []);

  if (loading) {
    return <h2>Loading...</h2>;
  }

  if (error) {
    return <h2>{error}</h2>;
  }

  return (
    <ul>
      {users &&
        users.map((user) => (
          <li key={user.id}>
            {user.name} - {user.email}
          </li>
        ))}
    </ul>
  );
};

export default withPrivateRoute(Users);
