import { gql } from '@apollo/client';

export const GET_USER_PROFILE = gql`
  query me {
    me {
      id
      accountId
      name
      email
      avatarUrl
    }
  }
`;

export const SAVE_USER_PROFILE = gql`
  mutation saveProfile($name: String!, $email: String!, $avatarUrl: String) {
    saveProfile(name: $name, email: $email, avatarUrl: $avatarUrl) {
      id
      accountId
      name
      email
      avatarUrl
    }
  }
`;
