import { withPrivateRoute } from '../../components/PrivateRoute/PrivateRoute';
import useFetch from '../../hooks/useFetch';
import { UserDto } from '../Users/Users';

const Profile = () => {
  const { loaded, data, error } = useFetch<UserDto[]>({
    url: new URL('/user', process.env.REACT_APP_API_URL),
  });

  if (!loaded) {
    return <div>Loading....</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <div>
      <h2>Profile</h2>
      <ul>
        {data &&
          data.map((user) => (
            <li key={user.id}>
              {user.name} - {user.email}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default withPrivateRoute(Profile);
