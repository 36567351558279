import useAuth from '../../hooks/useAuth';
import { Dashboard } from '../Dashboard';
import Landing from '../Landing/Landing';

const Home = () => {
  const auth = useAuth();

  if (auth) {
    return <Dashboard />;
  }

  return <Landing />;
};

export default Home;
