import React from 'react';

export interface PageTitleProps {
  title: string;
}
const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return <div className="page-title">{title}</div>;
};

export default PageTitle;
